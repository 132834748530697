import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'
import PageHeaderContactParallax from '../animations/page-header-contact-parallax'
import Form from '../modules/form'
import NationalPresenceMap from '../modules/national-presence-map'

export default class PageContact extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      header: viewStorage.current.querySelector('.PageHeaderContact'),
      form: viewStorage.current.querySelector('.Form'),
      nationalPresence: viewStorage.current.querySelector('.NationalPresence')
    }

    this.init()
  }

  init() {
    const {
      header = null,
      form = null,
      nationalPresence = null
    } = this.DOM

    if (header) this.headerParallaxModule = new PageHeaderContactParallax(header)
    // if (form) this.formModule = new Form(form)
    if (nationalPresence) this.nationalPresenceModule = new NationalPresenceMap(nationalPresence)
  }

  onLeaveCompleted() {
    this.headerParallaxModule && this.headerParallaxModule.destroy()
    this.formModule && this.formModule.destroy()
    this.nationalPresenceModule && this.nationalPresenceModule.destroy()
  }
}
